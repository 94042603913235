const actions = {
  // // 设置课件类型
  // async setMaterialsType(context, response){
  //   await context.commit('setMaterialsType', response);
  // },
  // // 设置课程内容
  // async setCourseData(context, response){
  //   await context.commit('setCourseData', response);
  // },
  // // 设置白板详细信息列表查询条件
  // async setWhiteboard_querycondition(context, response){
  //   await context.commit('setWhiteboard_querycondition', response);
  // },
  // // 设置白板中点击的词语信息
  // async setWhiteboardWordClickInfo(context, response){
  //   await context.commit('setWhiteboardWordClickInfo', response);
  // },
  // // 设置白板中hover的词语信息
  // async setWhiteboardWordHoverInfo(context, response){
  //   await context.commit('setWhiteboardWordHoverInfo', response);
  // },
  // // 设置学生信息
  // async setStudentData(context, response){
  //   await context.commit('setStudentData', response);
  // },
  // // 设置字体缩放比例
  // async setFontZoom(context, response){
  //   await context.commit('setFontZoom', response);
  // }
}

export default actions;