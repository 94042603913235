import Vue from "vue";
import VueRouter from "vue-router";
import Router from "vue-router";

Vue.use(VueRouter);
let data = {
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      redirect: "/dashboard/home",
      component: () =>
        import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard"),
      children: [
        {
          path: "home",
          name: "Home",
          component: () => import("@/views/Dashboard/Home"),
          meta: {
            keepAlive: true,
          },
        },
        {
          path: "bookclass",
          name: "BookClass",
          component: () => import("@/views/Dashboard/BookClass"),
          meta: {
            title: "bookclass",
            // noToken: true
          },
        },
        {
          path: "myorders",
          name: "MyOrders",
          component: () => import("@/views/Dashboard/MyOrders"),
          meta: {
            title: "myorders",
            // noToken: true
          },
        },
        {
          path: "prices",
          name: "Prices",
          component: () => import("@/views/Dashboard/Prices"),
          meta: {
            title: "prices",
            // noToken: true
          },
        },
        {
          path: "profile",
          // redirect:'/profile?page=0',
          name: "Profile",
          component: () => import("@/views/Dashboard/Profile"),
          meta: {
            title: "profile",
            // noToken: true
          },
        },
        {
          path: "referafriend",
          name: "ReferAFriend",
          component: () => import("@/views/Dashboard/ReferAFriend"),
          meta: {
            title: "referafriend",
            // noToken: true
          },
        },
        {
          path: "Review",
          name: "Review",
          component: () => import("@/views/Dashboard/Review"),
          meta: {
            title: "Review",
            // noToken: true
          },
        },
        {
          path: "Schedule",
          name: "Schedule",
          component: () => import("@/views/Dashboard/Schedule"),
          meta: {
            title: "Schedule",
            // noToken: true
          },
        },
      ],
    },
    //kidsPreview模块
    {
      path: "/course/preview/calendarEventId=:calendarEventId/lesson:lessonNo/:lessonId",
      name: "Preview",
      component: () =>
        import(
          /* webpackChunkName: "CourseDashboard" */ "@/views/Preview/index.vue"
        ),
      meta: {
        title: "PREVIEW",
      },
    },
    {
      path: "/course/calendarEventId=:calendarEventId/lesson:lessonNo/:lessonId",
      name: "CourseDashboard",
      component: () =>
        import(
          /* webpackChunkName: "CourseDashboard" */ "@/views/CourseDashboard/index.vue"
        ),
      meta: {
        title: "CLASS",
      },
    },
    {
      path: "/login",
      name: "LogIn",
      component: () => import(/* webpackChunkName: "login" */ "@/views/Login"),
    },
  ],
};

let router = new Router(data);

export default router;
