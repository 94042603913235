import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { checkImg, throttle } from "./directives";

// element ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";
// Vue.use(ElementUI, {locale}, VueDND)
Vue.use(ElementUI, {
  locale,
});
// 全局指令
Vue.directive("checkgif", checkImg);
Vue.directive("throttle", throttle);

// eventsBus 事件总线
import "./eventsBus";

// global css
import "@/assets/style/index.scss";

function localTake(item) {
  return localStorage.getItem(item) ? true : false;
}

// 路由全局守卫
router.beforeEach((to, from, next) => {
  let isLogin = localTake("userData"); // 判断是否登录，本地存储有用户数据则视为已经登录
  const whiteList = ["LogIn"];
  if (whiteList.indexOf(to.name) > -1) {
    next();
  } else {
    // 未登录状态；跳转至 LogIn
    if (!isLogin) {
      // 如果未登录（本地存储无用户数据），并且要跳到登录页面
      if (to.name !== "LogIn") {
        next({
          name: "LogIn",
          params: {
            redirect: to.fullPath,
          },
        });
        // next()
      } else {
        next();
      }
      // 登录后，跳到到当前页面
      console.log(from, to.fullPath, to);
    } else {
      const userData = JSON.parse(localStorage.getItem("userData"));
      store.commit("setUserInfo", userData);
      // 判断家长 or kids登录
      // console.log(JSON.parse(localStorage.getItem('isKids')));
      store.commit(
        "setUserIdentity",
        JSON.parse(localStorage.getItem("isKids"))
      );

      // 已登录状态；当路由到 UserLogIn 时，跳转至 Home
      if (to.name === "logIn") {
        if (isLogin) {
          next("/");
          return;
        }
      }
      next(); // 必须使用 next ,执行效果依赖 next 方法的调用参数
    }
  }
});

Vue.config.productionTip = false;

let vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

export default vue;
