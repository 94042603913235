import Axios from 'axios'
import vue from '@/main'

/*是否有请求正在刷新token*/
window.isRefreshing = false
/*被挂起的请求数组*/
let refreshSubscribers = []
/*获取刷新token请求的token*/
function getRefreshToken() {
  return JSON.parse(localStorage.userData).refreshToken
}
/*push所有请求到数组中*/
function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb)
}
/*刷新请求（refreshSubscribers数组中的请求得到新的token之后会自执行，用新的token去请求数据）*/
function onRrefreshed(token) {
  refreshSubscribers.map((cb) => cb(token))
}
/*判断token是否过期*/
function isTokenExpired() {
  /*从sessionStorage中取出token过期时间*/
  let expiredTime =
    new Date(JSON.parse(localStorage.userData).expiredTime).getTime() / 1000
  /*获取本地时间*/
  let nowTime = new Date().getTime() / 1000
  /*获取校验时间差*/
  // let diffTime = JSON.parse(sessionStorage.diffTime)
  /*校验本地时间*/
  // nowTime -= diffTime
  /*如果 < 5分钟，则说明即将过期*/
  return expiredTime - nowTime < 60 * 5
  // return false
}
let axios = Axios.create({
  // baseURL: process.env.API_ROOT,
  baseURL: `${process.env.VUE_APP_URL}/SmartLingo/`, //before
  // baseURL: 'https://api.nihaocafe.com/SmartLingo/',
  // baseURL: 'http://47.116.22.56:8084/SmartLingo/',
  // baseURL: 'http://49.235.8.136:8084/SmartLingo/',
  // baseURL: 'http://192.168.31.14:8084/SmartLingo/',//hu
  // baseURL: 'https://testapi.nihaocafe.com/SmartLingo/', // shmily
  // https://testapi.nihaocafe.com

  timeout: 1000 * 30,
  // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  headers: {
    'Content-Type': 'application/json'
  }
})
//
let loading
// 请求拦截器
axios.interceptors.request.use(
  async (config) => {
    // console.log(config)
    loading = vue.$loading({
      lock: true,
      text: '',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.3)'
    })

    const authTmp = localStorage.userData
    /*判断是否已登录*/
    if (authTmp) {
      /*解析登录信息*/
      let auth = JSON.parse(authTmp)
      // config.data ? config.data.userId = auth.userSetting.userId : '';
      /*判断auth是否存在*/
      if (auth) {
        config.headers.token = auth.token
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
axios.interceptors.response.use(
  (res) => {
    loading.close()

    if (res.data.code !== 200) {
      if (res.data.code == 401) {
        localStorage.removeItem('userData')
        let href = window.location.href.split('/admin/#')[0]
        window.location.href = href
      } else {
        vue.$message.error(res.data.message)
      }
      return
    }

    return Promise.resolve(res.data)
  },
  (error) => {
    // console.log(error.request)
    // console.log(error.response)
    let message = error.response
      ? `${error.response.config.url}  ${error.response.status}`
      : error.message
    loading.close()
    vue.$message.error(message)
    return Promise.reject(error)
  }
)

export default axios
