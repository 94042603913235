import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

import dashboard from './dashboard'
// import toolBar from './toolBar'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    dashboard,
    // toolBar,
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })]
})