const getters = {
  // 获取课件类型
  getMaterialsType(state){
    return state.materialsType;
  },
  // 获取课程内容
  getCourseData(state){
    // console.log(state);
    return state.courseData;
  },
  // 获取白板详细信息列表查询条件
  getWhiteboard_querycondition(state){
    return state.whiteboard_querycondition;
  },
  // 获取白板中点击的词语信息
  getWhiteboardWordClickInfo(state){
    return state.whiteboardWordClickInfo;
  },
  // 获取白板中hover的词语信息
  getWhiteboardWordHoverInfo(state){
    return state.whiteboardWordHoverInfo;
  },
  // 获取学生信息
  getStudentData(state){
    return state.studentData;
  },
  // 获取字体缩放比例
  getFontZoom(state){
    return state.fontZoom;
  }
}

export default getters;