const mutations = {
  // 设置学生信息
  setUserInfo(state, response) {
    state.userInfo = response;
  },
  // 老师头像
  setTeacherAvatar(state, response) {
    state.teacherAvatar = response;
  },
  // 设置默认kid学生信息
  setKidUserId(state, response) {
    // console.log(response);
    state.kidUserId = response;
  },
  // 设置默认kid学生index
  setKidUserIndex(state, response) {
    // console.log(response);
    state.kidUserIndex = response;
  },
  // 设置默认kid学生信息
  setUserIdentity(state, response) {
    // console.log(response);
    state.isKid = response;
  },
  // 设置默认kid学生课程信息
  setCourseData(state, response) {
    // console.log(response);
    state.courseInfo = response;
  },


}

export default mutations;