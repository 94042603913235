const correctGIf = require("@/assets/img/05-Panda/panda-correct.gif")
const wrongGIf = require("@/assets/img/05-Panda/panda-wrong.gif")

export const checkImg = {

    inserted(el, binding) {
        // console.log(el);
        let img = document.createElement("img");
        el.classList.add("checkGif")
        if (binding.value) {
            img.src = correctGIf
        } else {
            img.src = wrongGIf
        }
        el.appendChild(img);
    },
}

export const throttle = {
inserted(el, bind){
    el.addEventListener('click', function() {
        let isFalse= false
        if (!isFalse) {
            isFalse = true
            el.style.pointerEvents = 'none';
            setTimeout(() => {
            el.style.pointerEvents = 'auto';
                isFalse = false
            }, 1000);
        }
    })
 }
}