<template>
  <div id="app">
    <!-- <h1 class="test">你好Kids</h1> -->
    <router-view />
  </div>
</template>
<script>
import { userLogin } from "@/assets/js/axios/kidsApi.js";
export default {
  name: "nihaoKids",
  data() {
    return {
      show: 0,
    };
  },
  // async created() {
  //   // 测试kids账号
  //   // 测试新电脑
  //   // 开发账号 1
  //   const username = "chad_student1@test.com";
  //   const password = "123456";

  //   // 测试账号 2
  //   // const username = "testkids2@sidaorui.com";
  //   // const password = "Welldone@1978";

  //   // 测试账号 3
  //   // const username = "kidsTestStudent@123.com";
  //   // const password = "Welldone@1978";

  //   const result = await userLogin({
  //     username,
  //     password,
  //   });
  //   localStorage.userData = JSON.stringify(result.data);
  //   this.$store.commit("setUserInfo", result.data);
  //   // console.log(this.$store.state.dashboard.userInfo.userSetting);
  // },
};
</script>
<style lang="scss" scoped>
#app {
  width: 100vw;
  height: 100vh;
}
</style>
